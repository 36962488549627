.work-card {
  width: 100%;
  max-width: 600px;
  height: 350px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: 15px;

  &:hover {
    filter: sepia(25%);
  }
}

@media(max-width: 775px) {
  .work-card {
    height: 275px;
  }
}

.work-card-link {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-bottom: none !important;
}

.work-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  filter: brightness(80%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.work-card-title {
  position: absolute;
  right: 20px;
  top: 15px;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 10px 0 10px;
  width: fit-content;
  backdrop-filter: brightness(40%);
}

.work-card-about {
  position: absolute;
  width: fit-content;
  max-width: 90%;
  padding: 0 10px 0 10px;
  bottom: 15px;
  color: black;
  font-size: 20px;
  background-color: white;
  border-radius: 5px;
}

.left-20 {
  left: 20px;
}

.right-20 {
  right: 20px;
}

.work-cover-image {
  width: auto;
  height: 450px;
  max-width: 635px;
  display: block;
  margin: auto;
  position: relative;
  border-radius: 10px;
}

@media(max-width: 775px) {
  .work-cover-image {
    height: 300px;
    max-width: 425px;
  }
}

.work-page-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  filter: brightness(97%);
}

.work-page-author-link {
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: 1px solid black underline;
    text-underline-offset: 3px;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 3;
  max-height: 75px;
  margin: auto;
  border-radius: 50%;
  max-width: 100%;

  &:hover {
    background-color: transparent;
  }
}