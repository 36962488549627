.article-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  margin: 10px;
  overflow: hidden;
  max-width: 400px;
  max-height: 300px;
  position: relative;
  height: 200px;

  &:hover {
    filter: brightness(90%);
  }
}

.article-card-link {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-bottom: none !important;
}

.article-body {
  margin: 10px;
  position: relative;
  width: 100%;
}

.article-card-title {
  width: 100%;
  height: 100%;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-icon {
  z-index: 0;
  border: none;
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 5%;
  filter: opacity(5%);
  color: rgb(200, 200, 200);
}

.article-like {
  z-index: 4;
  border: none;
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    background-color: inherit;
  }
}

.like-icon::before {
  content: "\f1a2";
  color: black;
}

.like-icon:hover::before {
  content: "\f196";
  color: black;
}

.liked-icon::before {
  content: "\f195";
  color: black;
}

.liked-icon:hover::before {
  content: "\f198";
  color: black;
}

.article-quote-block, .article-quote-block-ar {
  width: auto;
  height: auto;
  margin: 10px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.article-quote-block {
  border-left: 7px solid rgba(40, 167, 69, 0.5);
}

.article-quote-block-ar {
  border-right: 7px solid rgba(40, 167, 69, 0.5);
}

.article-quote {
  font-weight: normal !important;
  background-color: rgba(40, 167, 69, 0.3);
  padding: 0 3px 0 3px;
}

.article-item {
  width: auto;
  height: auto;
  margin: 10px;
  padding: 15px;
  font-size: 18px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.accordion-flush {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.accordion-button {
  font-weight: bold;
  font-size: 16px;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: rgba(185, 185, 185, 0.15);
}

.accordion-button:focus {
  border-color: #ababab;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.accordion-button-ar::after {
  margin-left: 0;
  margin-right: auto;
}

.breadcrumb-link {
  font-size: 14px;
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: black underline 1px;
    text-underline-offset: 5px;
    transition: transform .3s ease;;
  }
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item {
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
}

.breadcrumb-item-rtl {
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
}

.breadcrumb-item-rtl + .breadcrumb-item-rtl {
  padding-right: 0.5rem;
}

.breadcrumb-item-rtl + .breadcrumb-item-rtl::before {
  float: right;
  padding-left: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
}

.article-cover-image {
  width: 100%;
  height: 350px;
  position: relative;
  border-radius: 10px;
  display: flex;
  margin: auto;
  max-width: 495px;
}

@media(max-width: 550px) {
  .article-cover-image {
    height: 250px;
    max-width: 355px;
  }
}

.article-page-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  filter: brightness(97%);
}

.article-page-fav {
  border: none;
  font-size: 30px;

  &:hover {
    background-color: inherit;
  }
}

.article-form-header {
  font-size: 14px;
  text-align: center;
  color: #999999;
}