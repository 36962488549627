.header-logo {
  max-height: 70px;

  &:hover {
    filter: brightness(70%);
    transition: 0.2s;
  }
}

.nav-link {
  transform: scale(1, 0.8);
  font-weight: bold;
  color: white;
  margin: 0 7px;

  &:focus {
    color: white;
  }

  &:hover {
    color: white;
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-offset: 4px;
  }
}

.nav-header-link {
  font-size: 16px;
  line-height: 16px;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
  margin: 0 5px;
  white-space: nowrap;
}

.nav-icon {
  margin: 0 20px 0 20px;
  transform: scale(1, 0.9);
}

.nav-bi-icon {
  color: white;
  font-size: 26px;

  &:hover {
    color: #a6a6a6;
    transition: 0.2s;
  }
}

.navbar-vertical-separator {
  width: 1px;
  height: 28px;
  display: block;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.2s ease;
  margin: 0 2px;
}

.navbar-toggler {
  border: none;
  font-size: 28px;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    box-shadow: none;
  }
}