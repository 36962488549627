.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.3);
}

input {
  box-shadow: inset 0 2px 2px rgb(0 0 0 / 10%);
  background: transparent;
}

button {
  background-color: transparent;
  border-radius: 7px;

  &:hover {
    background-color: rgba(246, 246, 246, 0.75);
  }
}

.auth-form {
  width: 660px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 5%);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px 20px 30px;
}

.btn-form {
  width: 75%;
  margin-top: 1rem;
  padding: 8px 10px 8px 10px;
}

.profile-header {
  max-width: 660px;
}

.auth-form-title {
  text-align: center;
  color: #1a1a1a;
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  transform: scale(1, .9);
}

.auth-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 20px 30px;
}

.auth-link-text {
  font-size: 13px;
  color: #999999;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transform: scale(1, .9);
}

.auth-link-text a {
  position: relative;
  color: black;
  text-decoration: none;
  line-height: 24px;

  &:before, &:after {
    content: '';
    position: absolute;
    height: 1px;
    transition: transform .3s ease;
  }

  &:hover {
    color: black;
  }
}

.show-password {
  position: absolute;
  top: 65%;
  right: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  transform: translateY(-50%);

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.show-password-left {
  position: absolute;
  top: 65%;
  left: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  transform: translateY(-50%);

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.account-nav {
  width: 100%;
  margin: 15px 0 50px 0;
}

.account-nav-items {
  margin: auto auto 5px auto;
  max-width: 700px;
  --bs-nav-tabs-border-width: none !important;
  --bs-nav-tabs-link-active-bg: none !important;
}

.account-nav-item {
  color: gray !important;
  font-size: 20px;
  transform: scale(1, 0.9) !important;
}

ul > li > .account-nav-item {
  &:hover {
    text-decoration: none;
  }
}

ul > li > .account-nav-item.active {
  color: black !important;
  text-decoration-line: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}

.account-tab-content {
  margin: 15px;
}

.account-name {
  font-size: 27px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 5px;
}

.account-registration {
  color: #999999;
  font-size: 13px;
  line-height: 18px;
}

.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  margin: 0 15px 0 0;
  font-size: 15px;
}

.logout-bi-icon {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.account-bi-icon {
  font-size: 60px;
}