* {
  font-family: Formular, SF Pro Ar, sans-serif;
  font-weight: normal;

  padding: 0;
  margin: 0;
  outline: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

main {
  flex: 1 1 auto;
  background-color: #f7f7f7;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  background-color: white;
}

label {
  text-align: center;
  color: #999999;
  font-size: 11px;
  line-height: 15px;
  transform: scale(1, .9);
}

.link {
  padding-top: 10px;

  &:before {
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background: darken(#000, 10%);;
    transform: scaleX(0);
  }

  &:hover:before {
    transform: scaleX(1);
  }
}

.information {
  font-size: 24px;
  text-align: center;
  margin: 100px 10px 100px 10px !important;
}

.information-icon {
  font-size: 64px;
}

.form-error {
  text-align: center;
  box-sizing: border-box;
  padding: 0 25px;
  color: #f00;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin: -7px auto 36px;
}
