.contacts-header {
  font-size: 42px;
  font-weight: bold;
  transform: scale(1, 0.9);
  padding-bottom: 25px;
}

.mail-icon {
  font-size: 56px;
}

.main-content {
  max-width: 900px;
  padding: 20px;
}

.main-text {
  font-size: 18px;
  transform: scale(1, 0.9);
  margin: 0;
  color: #363636;
}

.email-text {
  color: black;
  font-weight: bold;
  transform: scale(1, 0.9);
  text-underline-offset: 4px;

  &:before, &:after {
    transition: all 0.2s ease;
  }

  &:hover {
    color: black;
    text-decoration: none;
    text-decoration-color: black;
    text-underline-offset: 3px;
  }
}