.team-header {
  font-size: 42px;
  font-weight: bold;
  transform: scale(1, 0.9);
  padding-bottom: 25px;
}

.team-position-icon {
  font-size: 45px;
}

.team-position {
  font-size: 16px;
  margin: 0 0 10px 0;
  transform: scale(1, 0.9);
}

.team-full-name {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  transform: scale(1, 0.9);
}

.team-status {
  margin: 0;
  transform: scale(1, 0.9);
}