.about-icon {
  font-size: 56px;
}

.main-content {
  max-width: 1000px;
  padding: 20px;
}

.about-text {
  font-size: 20px;
  transform: scale(1, 0.9);
  margin: 0;
  color: #363636;
  white-space: pre-line;
  text-align: justify;
}

.source-item {
  font-size: 18px;
  text-align: justify;
  white-space: pre-line;
}