.footer-text {
  font-size: 13px;
  text-transform: uppercase;
}

.footer-image {
  width: 100%;
}

.site-footer {
  background-color: black;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}

.copyright-text {
  margin: 0;
  text-align: center;
  font-size: 11.5px;
  line-height: 15px;
  color: gray;
}

.language {
  width: 95px;
  height: 27px;
  margin: auto;
}

.language-container-left, .language-container-right {
  float: left;
  width: 40px;
  height: 27px;
  margin: 0 3px;
  position: relative;
}

.language-control {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.language-control:checked + .flag-image {
  filter: brightness(95%);
}

.language-control + .flag-image:hover {
  filter: brightness(95%);
}

.flag-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  border-radius: 3px;
  object-fit: cover;
  object-position: top;
  filter: brightness(50%);
}