.contribution-header {
  text-align: center;
  max-width: 700px;
  font-size: 21px;
}

.contribution-icon {
  font-size: 64px;
}

.contribution-form {
  width: 800px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 5%);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px 20px 30px;
}

.contribution-form-header {
  font-size: 16px;
  text-align: center;
  color: #999999;
}

.contribution-form-button {
  text-align: center;
  max-width: 200px;
}

.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.3);
}

.form-select:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.3);
}